import { render, staticRenderFns } from "./fiche_info_familles_principale_secondaires_capacites.vue?vue&type=template&id=24665db5"
import script from "./fiche_info_familles_principale_secondaires_capacites.js?vue&type=script&lang=js&external"
export * from "./fiche_info_familles_principale_secondaires_capacites.js?vue&type=script&lang=js&external"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports